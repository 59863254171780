import "./checkoutrate.css";
import { Select } from "../BasicComponents";
import { useEffect, useRef } from "react";
import HotelDatepicker from "hotel-datepicker";
import { useState } from "react";
import API from "src/API";
import { useNavigate } from "react-router-dom";

const i18n = {
  selected: "Votre séjour:",
  night: "Nuit",
  nights: "Nuits",
  button: "Fermer",
  clearButton: "Clear",
  submitButton: "Submit",
  "checkin-disabled": "Check-in disabled",
  "checkout-disabled": "Check-out disabled",
  "day-names-short": ["DIM", "LUN", "MAR", "MER", "JEU", "VEN", "SAM"],
  "day-names": [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  "month-names-short": [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Jui",
    "Juil",
    "Août",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ],
  "month-names": [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  "error-more": "Date range should not be more than 1 night",
  "error-more-plural": "Date range should not be more than %d nights",
  "error-less": "Date range should not be less than 1 night",
  "error-less-plural": "Date range should not be less than %d nights",
  "info-more": "Veuillez sélectionner une plage de dates apratir de 1 nuit",
  "info-more-plural":
    "Veuillez sélectionner une plage de dates d'au moins %d nuits",
  "info-range": "Please select a date range between %d and %d nights",
  "info-range-equal": "Please select a date range of %d nights",
  "info-default": "Please select a date range",
};

const date = new Date();

localStorage.setItem(
  "starting_date",
  date.getDate() +
    " " +
    i18n["month-names-short"][date.getMonth()] +
    " " +
    date.getFullYear()
);

localStorage.setItem(
  "ending_date",
  date.getDate() +
    1 +
    " " +
    i18n["month-names-short"][date.getMonth()] +
    " " +
    date.getFullYear()
);

const CheckOutRateHome = () => {
  const elm = useRef(null);
  const ref = useRef();

  const [nbAdulte, setNbAdulte] = useState(null);
  const [nbChildren, setNbChildren] = useState(null);

  useEffect(() => {
    window.fecha = require("fecha");
    var datepicker = new HotelDatepicker(elm.current, {
      format: "DD MMM YYYY",
      infoFormat: "DD MMM YYYY",
      disabledDates: ['2024-09-27'],
      // i18n,
    });

    // check code to fix date1 issue
    datepicker.setValue(
      localStorage.getItem("starting_date") +
        "- " +
        localStorage.getItem("ending_date")
    );
    datepicker.onSelectRange = () => {
      let start = datepicker.getValue().split(" - ")[0];
      // let s_day = start.split(" ")[0];
      // let s_month = i18n["month-names-short"].indexOf(start.split(" ")[1]) + 1;
      // let s_year = start.split(" ")[2];
      localStorage.setItem("starting_date", start);

      let end = datepicker.getValue().split(" - ")[1];
      // let e_day = end.split(" ")[0];
      // let e_month = i18n["month-names-short"].indexOf(end.split(" ")[1]) + 1;
      // let e_year = end.split(" ")[2];
      localStorage.setItem("ending_date", end);
    };
  }, []);

  const navigate = useNavigate();

  // const checkRateHome = () => {
  //   let startDate = localStorage.getItem("starting_date");
  //   let finishDate = localStorage.getItem("ending_date");

  //   startDate = startDate.split(" ");
  //   finishDate = finishDate.split(" ");

  //   startDate[1] = i18n["month-names-short"].indexOf(startDate[1]) + 1;
  //   finishDate[1] = i18n["month-names-short"].indexOf(finishDate[1]) + 1;

  //   startDate = startDate[2] + "-" + startDate[1] + "-" + startDate[0];
  //   finishDate = finishDate[2] + "-" + finishDate[1] + "-" + finishDate[0];

  //   API.post("roomtype/check", {
  //     startDate,
  //     finishDate,
  //   })
  //     .then((resp) => {
  //       navigate("/chambres", { state: resp.data.data });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  useEffect(() => {
    ref.current.onclick = () => {
      // checkRateHome();
      let startDate = localStorage.getItem("starting_date");
      let finishDate = localStorage.getItem("ending_date");

      startDate = startDate.split(" ");
      finishDate = finishDate.split(" ");

      startDate[1] = i18n["month-names-short"].indexOf(startDate[1]) + 1;
      finishDate[1] = i18n["month-names-short"].indexOf(finishDate[1]) + 1;

      startDate = startDate[2] + "-" + startDate[1] + "-" + startDate[0];
      finishDate = finishDate[2] + "-" + finishDate[1] + "-" + finishDate[0];

      // API.post("roomtype/check", {
      //   startDate,
      //   finishDate,
      // })
      //   .then((resp) => {
      navigate("/chambres"); //, { state: resp.data.data });
      // })
      // .catch((e) => {
      //   console.log(e);
      // });
    };
  }, [navigate]);

  return (
    <div className="checkoutratehome">
      <input type="text" ref={elm} />
      <Select
        nb={2}
        name="Guests"
        list={[1, 2, 3]}
        setVal={setNbAdulte}
        val={nbAdulte}
        zIndex={10}
      />
      <Select
        nb={5}
        name="Childrens"
        list={[1, 2, 3]}
        setVal={setNbChildren}
        val={nbChildren}
        zIndex={1}
      />
      <button ref={ref}>CHECK RATES</button>
    </div>
  );
};

const CheckOutRateChambre = ({ setRooms }) => {
  const elm = useRef(null);
  const ref = useRef();

  useEffect(() => {
    window.fecha = require("fecha");
    var datepicker = new HotelDatepicker(elm.current, {
      format: "DD MMM YYYY",
      infoFormat: "DD MMM YYYY",
      disabledDates: ['2024-12-31'],
	    // i18n,
    });

    datepicker.setValue(
      localStorage.getItem("starting_date") +
        "- " +
        localStorage.getItem("ending_date")
    );
    datepicker.onSelectRange = () => {
      let start = datepicker.getValue().split(" - ")[0].split(" ");
      let s_day = start[0];
      let s_month = i18n["month-names-short"].indexOf(start[1]) + 1;
      let s_year = start[2];
      localStorage.setItem(
        "starting_date",
        s_month + "/" + s_day + "/" + s_year
      );

      let end = datepicker.getValue().split(" - ")[1].split(" ");
      let e_day = end[0];
      let e_month = i18n["month-names-short"].indexOf(end[1]) + 1;
      let e_year = end[2];
      localStorage.setItem("ending_date", e_month + "/" + e_day + "/" + e_year);
    };
  }, []);

  // const navigate = useNavigate();

  // const checkRateRooms = () => {
  //   let startDate = localStorage.getItem("starting_date");
  //   let finishDate = localStorage.getItem("ending_date");

  //   startDate = startDate.split("/")[0].split(" ");
  //   finishDate = finishDate.split("/")[0].split(" ");

  //   startDate[1] = i18n["month-names-short"].indexOf(startDate[1]) + 1;
  //   finishDate[1] = i18n["month-names-short"].indexOf(finishDate[1]) + 1;

  //   startDate = startDate[2] + "-" + startDate[1] + "-" + startDate[0];
  //   finishDate = finishDate[2] + "-" + finishDate[1] + "-" + finishDate[0];

  //   API.post("roomtype/check", {
  //     startDate,
  //     finishDate,
  //   })
  //     .then((resp) => {
  //       setRooms([
  //         ...resp.data.data.currentRate[0].rateDetails,
  //         ...resp.data.data.packages[0].rateDetails,
  //       ]);
  //     })
  //     .catch((e) => {});
  // };

  useEffect(() => {
    ref.current.onclick = () => {
      // checkRateRooms();
      let startDate = localStorage.getItem("starting_date");
      let finishDate = localStorage.getItem("ending_date");

      startDate = startDate.split("/")[0].split(" ");
      finishDate = finishDate.split("/")[0].split(" ");

      startDate[1] = i18n["month-names-short"].indexOf(startDate[1]) + 1;
      finishDate[1] = i18n["month-names-short"].indexOf(finishDate[1]) + 1;

      startDate = startDate[2] + "-" + startDate[1] + "-" + startDate[0];
      finishDate = finishDate[2] + "-" + finishDate[1] + "-" + finishDate[0];

      API.post("roomtype/check", {
        startDate,
        finishDate,
      })
        .then((resp) => {
          setRooms([
            ...resp.data.data.currentRate[0].rateDetails,
            ...resp.data.data.packages[0].rateDetails,
          ]);
        })
        .catch((e) => {});
    };
  }, [setRooms]);

  return (
    <div className="checkoutratechambres">
      <input type="text" ref={elm} className="checkchambre_input" />
      <span className="checkchambre_span">1-night stay</span>
      <button className="checkchambre_button" ref={ref}>
        CHECK RATES {">"}
      </button>
    </div>
  );
};

export { CheckOutRateHome, CheckOutRateChambre };
